@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 100;
}

.agriculture-wrapper {
    position: relative;
    margin-top: 22.5%;
    display: flex;
    z-index: 4;
    flex-direction: column;
}

.agriculture-bg {
    position: absolute;
    width: 100%;
    top: -21%;
    z-index: 0;
}

.patternbg {
    position: absolute;
    right: 0%;
    top: -56%;
    z-index: 1;
}

.inner-agriculture {
    display: flex;
    flex-direction: column;
    gap: 129px;
    padding-left: 8%;
    position: relative;

}

.agriculture-wrapper video {
    position: absolute;
    opacity: 0.5;
    width: 69%;
    right: 0%;
    top: 34%;
    z-index: -1;
}

.agriculture-heading h1 {
    font-family: 'Trap';
    font-size: 73.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 105.336%;
    /* 62.722px */
    letter-spacing: -2.572px;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.agri-subsections {
    display: flex;
    padding-left: 29%;
    position: relative;
    z-index: 1;
}

.agri-embodies {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 43%;
    column-gap: 62px;
}

.agri-embodies h2 {
    color: #FFF;
    font-family: 'Trap';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 131.2%;
    /* 31.488px */
    letter-spacing: -0.12px;
    text-transform: uppercase;
}

.agri-bg {
    position: absolute;
    top: 19%;
    left: 16%;
    scale: 1.2;
}

.agri-context-bullets {
    width: 100%
}

.agri-context-bullets p {
    margin: 0;
    color: #FFF;
    font-family: 'Trap';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 154.7%;
    /* 27.846px */
    letter-spacing: -0.45px;
}

.agri-content-bullets-main {
    display: flex;
    gap: 30px;
    align-items: baseline;
}

.vignette {
    position: absolute;
    top: 32%;
    right: 0;
    width: 1460.19px;
    height: 753.03px;
    box-shadow: 116px 0px 90px 132px rgba(24, 24, 24, 1) inset;
    z-index: -1;
}

.agri-bg-mob {
    display: none;
}

@media (max-width: 1680px) {
    .vignette {
        width: 1200px;
        height: 669px;
    }
    .agri-subsections {
        display: flex;
        padding-left: 31%;
        position: relative;
        z-index: 1;
    }
    .agri-embodies {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 52%;
        column-gap: 62px;
    }
}

@media (max-width: 1584px) {
    .agriculture-bg {
        top: -22%;
    }

    .agriculture-wrapper {
        margin-top: 19.9%;
    }

    .agri-subsections {
        padding-left: 32%;
    }

    .agri-embodies {
        width: 52%;
        column-gap: 52px;
    }

    .vignette {
        width: 1200px;
        height: 610px;
    }

    .agri-bg {
        position: absolute;
        top: 19%;
        left: 14%;
        scale: 1.1;
    }
}

@media (max-width: 1440px) {
    .agri-bg {
        left: 11%;
    }

    .agri-embodies {
        width: 54%;
    }
}

@media (max-width: 1440px) and (max-height: 900px) {
    .vignette {
        width: 1099.31px;
        height: 619.86px;
    }
}

@media (max-width:1366px) {
    .agriculture-wrapper {
        position: relative;
        margin-top: 15.8%;
        display: flex;
        flex-direction: column;
    }

    .agriculture-bg {
        position: absolute;
        width: 100%;
        top: -20%;
        z-index: 0;
    }

    .agri-bg {
        position: absolute;
        top: 17%;
        left: 7.5%;
        scale: 1;
    }

    .inner-agriculture {
        display: flex;
        flex-direction: column;
        gap: 110px;
        padding-left: 8%;
        position: relative;

    }

    .agri-subsections {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding-left: 0;
    }

    .agri-embodies {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 40%;
        padding-left: 1.5%;
        padding-right: 0;
        column-gap: 4px;
    }

    .vignette {
        position: absolute;
        top: 32%;
        right: 0%;
        width: 1121px;
        height: 584.03px;
        box-shadow: 49px 0px 90px 140px rgba(25, 25, 25, 1) inset;
        z-index: -1;
    }
}

@media (max-width: 1280px) {
    .agri-bg {
        left: 5.5%;
    }

    .vignette {
        width: 997px;
        height: 559.03px;
    }
}

@media (max-width:1024px) {
    .agri-bg {
        left: -12.5%;
    }

    .agri-subsections {
        display: flex;
        justify-content: start;
        position: relative;
        z-index: 1;
        width: 100%;
        padding-left: 19%;
    }

    .agri-embodies {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 62%;
        padding-left: 1.5%;
        padding-right: 0;
        column-gap: 2%;
    }
    .vignette {
        width: 844px;
        height: 475.03px;
    }
}
@media (max-width:820px) {
    .agriculture-wrapper {
        margin-top: 17%;
    }

    .agriculture-heading h1 {
        font-size: 33.27px;
    }

    .agri-embodies {
        width: 45%;
    }

    .agri-embodies h2 {
        font-size: 16px;
    }

    .agri-subsections {
        justify-content: start;
        padding-top: 8%;
        padding-left: 9%;
    }

    .inner-agriculture {
        gap: 0;
    }

    .agri-content-bullets-main {
        padding-top: 50%;
    }

    .agri-context-bullets p {
        font-size: 14.14px;
    }

    .agri-embodies h2:nth-child(2) {
        padding-top: 15%;
    }

    .agriculture-wrapper video {
        width: 100%;
    }

    .agri-bg {
        display: none;
    }

    .agri-bg-mob {
        display: block;
        position: absolute;
        top: 8%;
        left: 0%;
    }

    .vignette {
        position: absolute;
        top: 34%;
        right: 0%;
        width: 820px;
        height: 465.03px;
        box-shadow: 99px 0px 90px 112px rgba(25, 25, 25, 1) inset;
        z-index: -1;
    }
}
@media (max-width:490px){
    .vignette{
        width: 820px;
        height: 303.03px;
    }
    .agri-subsections {
        justify-content: start;
        padding-top: 11%;
        padding-left: 13%;
    }
    .agri-embodies{
        width: 80%;
    }
}
@media (max-width:450px) {
    .agriculture-wrapper {
        margin-top: 36%;
    }

    .agriculture-heading h1 {
        font-size: 33.27px;
    }

    .agri-embodies {
        width: 82%;
    }

    .agri-embodies h2 {
        font-size: 16px;
    }

    .agri-subsections {
        justify-content: start;
        padding-top: 12%;
    }

    .inner-agriculture {
        gap: 0;
    }

    .agri-content-bullets-main {
        padding-top: 50%;
    }

    .agri-context-bullets p {
        font-size: 14.14px;
    }

    .agri-embodies h2:nth-child(2) {
        padding-top: 15%;
    }

    .agriculture-wrapper video {
        width: 150%;
    }

    .agri-bg {
        display: none;
    }

    .agri-bg-mob {
        display: block;
        position: absolute;
        top: 8%;
        left: -18%;
    }

    .vignette {
        position: absolute;
        top: 34%;
        right: 0%;
        width: 610px;
        height: 329.02999999999997px;
        box-shadow: 99px 0px 90px 112px rgba(25, 25, 25, 1) inset;
        z-index: -1;
    }
}

@media (max-width:430px) {
    .vignette {
        height: 367.03px
    }
}
@media (max-width:414px) {
    .agri-embodies {
        width: 89%;
    }
    .agri-subsections{
        padding-left: 5%;
    }
}
@media (max-width: 375px) {
    .agri-embodies {
        width: 84%;
    }
}

@media (max-width: 360px) {
    .agri-bg-mob {
        display: block;
        position: absolute;
        top: 9%;
        left: -26%;
    }

    .agri-embodies {
        width: 94%;
    }

    .inner-agriculture {
        gap: 5px;
    }
}

@media (max-width:280px) {
    .agri-bg-mob {
        display: block;
        position: absolute;
        top: 7%;
        left: -60%;
        scale: 0.9;
    }

    .vignette {
        height: 293.03px;
    }

    .bullets {
        width: fit-content;
        gap: 11px;
    }
}