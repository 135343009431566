.privacy-banner {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 10%;
    margin-bottom: 10%;
    z-index: 5;
    position: relative;
}

.privacy-heading h1{
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Trap';
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 5%;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 5;
}

.privacy-banner h3 {
    font-weight: 600;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.privacy-banner p {
    color: white;
    width: fit-content;
    /* body */
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;
}

.privacy-banner a{
    width: fit-content;
    color: white;
}

.privacy-banner h2 {
    font-size: 26px;
}

.privacy-banner a:hover{
    color: rgba(142, 106, 225, 1);
}


@media (max-width:1366px) {
    .privacy-banner::before {
        height: 575px;
        width: 575px
    }

}

@media (max-width:920px) {
    .privacy-banner {
        margin-top: 30%;
    }
}

@media (max-width: 650px) {
    .privacy-banner::before {
        top: -396px;
        left: -306px;
    }

    .privacy-banner {
        margin-top: 30%;
    }
}

@media (max-width:640px) {
    .privacy-banner {
        margin-top: 30%;
    }
}

@media (max-width: 420px) {
    .privacy-banner {
        margin-top: 10%;
    }

    .privacy-heading h1{
        font-size: 42px;
    }
}

@media (max-width: 375px) {
    .privacy-banner {
        margin-top: 10%;
    }
}

@media (max-width: 360px) {
    .privacy-banner {
        margin-top: 10%;
    }
}

@media (max-width: 300px) {
    .privacy-banner {
        margin-top: 20%;
    }

}
