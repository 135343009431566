.AR-wrapper {
    margin-top: 25%;
    z-index: 5;
    position: relative;
    display: flex;
    width: 100%;
    padding-bottom: 22%;
}

.AR-wrapper-bg {
    position: absolute;
    width: 100%;
    top: -24.5%;
}

.ar-left {
    width: 63%;
    position: relative;
    z-index: 1;
    padding-left: 24%;
}


.ar-right {
    position: relative;
    z-index: 10;
    margin-top: 8%;
}

.ar-left img {
    position: absolute;
}

.ar-left video {
    position: absolute;
    z-index: -1;
    opacity: 0.6;
    border-top-left-radius: 132px;
    border-bottom-left-radius: 132px;
    left: 8.05%;
    width: 133.8%;
}

.arpattern {
    scale: 1.6;
    left: 26.6%;
    top: 31.5%;
}


.gradientAR {
    position: absolute;
    background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
    width: 231.874px;
    height: 528.911px;
    filter: blur(160px);
    left: 30%;
    top: -26%;
    opacity: 0.4;
    rotate: -45deg;
}

.AR-bg-black {
    position: absolute;
    width: 95%;
    right: 0;

    z-index: 1;
}

.ar-right .ar-context h1 {
    font-family: 'Trap';
    font-size: 73.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 120.336%;
    /* 88.447px */
    letter-spacing: -2.572px;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ar-right .ar-context p {
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 159.836%;
    /* 25.574px */
    letter-spacing: -0.56px;
    width: 460px;
}

.AR-logo {
    display: flex;
    margin-top: 5%;
    padding-bottom: 1%;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    overflow: hidden;
    z-index: 6;
    position: relative;
}

.AR-logo-subI {
    display: flex;
    gap: 20px;
    animation: leftmovement 20s infinite linear;
    position: relative;
}

.AR-logo-subII {
    display: flex;
    gap: 20px;
    animation: rightmovement 20s infinite linear;
}

.ar-left {
    flex: 1;
}

.ar-right {
    flex: 1;
}

@keyframes rightmovement {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }

}

@keyframes leftmovement {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }

}


.AR-logo::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%;
    background: linear-gradient(to right, rgba(24, 24, 24, 1), rgba(24, 24, 24, 0));
    z-index: 99999;
}

.AR-logo::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    background: linear-gradient(to left, rgba(24, 24, 24, 1), rgba(24, 24, 24, 0));
    z-index: 99999;
}

.vignetteAR {
    box-shadow: 116px 0px 90px 132px rgba(10, 10, 10, 1) inset;
    position: absolute;
    width: 1638px;
    height: 888px;
    rotate: 180deg;
    right: -47%;
    border-top-right-radius: 116px;
    border-bottom-right-radius: 116px;
}

@media (max-width:1912px) and (max-height:958px) {
    .vignetteAR {
        width: 1633px;
        height: 885px;
    }
}

@media (max-width: 1680px) {

    .ar-left video {
        width: 133.6%;
        border-top-left-radius: 116px;
        border-bottom-left-radius: 116px;
    }

    .arpattern {
        scale: 1.6;
        left: 29.6%;
        top: 31.5%;
    }

    .vignetteAR {
        box-shadow: 116px 0px 90px 132px rgba(10, 10, 10, 1) inset;
        position: absolute;
        width: 1446px;
        height: 783px;
        rotate: 180deg;
        right: -47%;
        border-top-right-radius: 116px;
        border-bottom-right-radius: 116px;
    }
}

@media (max-width: 1584px) {
    .AR-wrapper-bg {
        top: -20.5%;
    }

    .arpattern {
        left: 31.3%;
    }

    .ar-left video {
        border-top-left-radius: 105px;
        border-bottom-left-radius: 105px;
    }

    .vignetteAR {
        box-shadow: 116px 0px 90px 132px rgba(10, 10, 10, 1) inset;
        position: absolute;
        width: 1308px;
        height: 708px;
        rotate: 180deg;
        right: -47%;
        border-top-right-radius: 99px;
        border-bottom-right-radius: 99px;
        z-index: -1;
    }
}

@media (width: 1440px) {
    .arpattern {
        left: 32.6%;
    }

    .ar-left video {
        width: 133.7%;
        border-top-left-radius: 99px;
        border-bottom-left-radius: 97px;
    }

    .vignetteAR {
        box-shadow: 116px 0px 90px 132px rgba(10, 10, 10, 1) inset;
        position: absolute;
        width: 1240px;
        height: 671px;
        rotate: 180deg;
        right: -47%;
        border-top-right-radius: 99px;
        border-bottom-right-radius: 99px;
        z-index: -1;
    }
}

@media (max-width:1366px) {
    .AR-wrapper-bg {
        position: absolute;
        width: 100%;
        top: -21.5%
    }

    .arpattern {
        left: 20%;
        top: 20%;
        scale: 1.3;
    }

    .AR-logo {
        margin-top: 10%;
    }

    .ar-left video {
        border-top-left-radius: 93px;
        border-bottom-left-radius: 93px;
    }

    .vignetteAR {
        box-shadow: 116px 0px 90px 132px rgba(10, 10, 10, 1) inset;
        position: absolute;
        width: 1176px;
        height: 629px;
        rotate: 180deg;
        right: -47%;
        border-top-right-radius: 99px;
        border-bottom-right-radius: 99px;
        z-index: -1;
    }
}

@media (max-width: 1358px) {
    .vignetteAR {
        height: 626px;
        width: 1158px;
    }
}

@media (max-width:1280px) {
    .ar-left video {
        width: 133.7%;
        border-top-left-radius: 87px;
        border-bottom-left-radius: 87px;
    }

    .arpattern {
        left: 21%;
        top: 20%;
        scale: 1.3;
    }

    .vignetteAR {
        box-shadow: 116px 0px 90px 132px rgba(10, 10, 10, 1) inset;
        position: absolute;
        width: 1102px;
        height: 596px;
        rotate: 180deg;
        right: -47%;
        border-top-right-radius: 88px;
        border-bottom-right-radius: 88px;
        z-index: -1;
    }
}

@media (max-width:1024px) {
    .ar-left video {
        width: 150.6%;
        border-top-left-radius: 68px;
        border-bottom-left-radius: 70px;
        left: 9.05%;
    }

    .arpattern {
        top: 17%;
        left: -1%;
        scale: 0.9;
    }

    .vignetteAR {
        box-shadow: 116px 0px 90px 132px rgba(10, 10, 10, 1) inset;
        position: absolute;
        width: 870px;
        height: 477px;
        rotate: 180deg;
        right: -63%;
        border-top-right-radius: 66px;
        border-bottom-right-radius: 66px;
        z-index: -1;
    }

}

@media (max-width:820px) {

    .vignetteAR {
        display: none;
    }

    .gradientAR {
        position: absolute;
        background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
        width: 138.874px;
        height: 276.911px;
        filter: blur(160px);
        left: 0%;
        top: -125%;
        opacity: 0.4;
        rotate: -45deg;
    }

    .AR-wrapper {
        padding-left: 32px;
        height: 950px;
        overflow: hidden;
        flex-direction: column;
        padding-bottom: 0;
    }

    .ar-right .ar-context h1 {
        font-size: 33.27px;
    }

    .ar-right .ar-context p {
        font-size: 14.14px;
        padding-bottom: 22px;
        width: 560px
    }

    .ar-right {
        order: -1;
    }

    .AR-bg-black {
        position: absolute;
        width: 126%;
        right: 0%;
        bottom: 4%;
        left: 5%;
        z-index: 1;
    }

    .ar-left {
        order: 1;
        width: 100%;
    }

    .ar-left video {
        width: 114.5%;
        left: 1.1%;
        bottom: 8.6%;
        border-top-left-radius: 77px;
        border-bottom-left-radius: 75px;
    }

    .arpattern {
        scale: 1;
        left: -0.7%;
        top: -10%;
    }
}

@media (max-width:490px) {

    .vignette-AR {
        display: none;
    }


    .AR-wrapper {
        padding-left: 32px;
        height: 750px;
        overflow: hidden;
        flex-direction: column;
    }

    .ar-right .ar-context h1 {
        font-size: 33.27px;
    }

    .ar-right .ar-context p {
        font-size: 14.14px;
        padding-bottom: 22px;
        width: 320px;
    }

    .ar-right {
        order: -1;
    }

    .AR-bg-black {
        position: absolute;
        width: 126%;
        right: 0%;
        bottom: 4%;
        left: 5%;
        z-index: 1;
    }

    .ar-left {
        order: 1;
        width: 100%;
    }

    .ar-left video {
        width: 117.9%;
        left: -1.8%;
        border-top-left-radius: 45px;
        border-bottom-left-radius: 45px;
        bottom: 9.6%;
    }

    .arpattern {
        scale: 0.7;
        left: -31%;
        top: -10%;
    }

    .AR-logo::after,
    .AR-logo::before {
        width: 200px;
    }

    .AR-logo img {
        width: 79px;
        height: 39.5px;
    }
}

@media (max-width:450px) {

    .vignette-AR {
        display: none;
    }

    .AR-wrapper {
        padding-left: 32px;
        height: 700px;
        overflow: hidden;
        flex-direction: column;
    }

    .ar-right .ar-context h1 {
        font-size: 33.27px;
    }

    .ar-right .ar-context p {
        font-size: 14.14px;
        padding-bottom: 22px;
        width: 320px;
    }

    .ar-right {
        order: -1;
    }

    .AR-bg-black {
        position: absolute;
        width: 126%;
        right: 0%;
        bottom: 4%;
        left: 5%;
        z-index: 1;
    }

    .ar-left {
        order: 1;
        width: 100%;
    }

    .ar-left video {
        width: 120.5%;
        left: -3%;
        border-top-left-radius: 33px;
        border-bottom-left-radius: 33px;
    }

    .artop {
        scale: 0.5;
        left: -44%;
        top: 13%;
    }

    .armid {
        scale: 0.5;
        left: -60%;
        bottom: 45%;
    }

    .arbottom {
        scale: 0.5;
        left: -57%;
        top: 69%;
    }

    .ardots {
        left: 30%;
        bottom: 36%;
        scale: 0.5;
    }

    .vertical {
        left: 30.2%;
        scale: 0.3;
        top: 19%;
    }

    .ardot {
        bottom: 20%;
        scale: 0.5;
        left: 30%;
    }

    .ardot-a {
        top: 68.7%;
        scale: 0.5;
        left: 22%;
    }

    .arpattern {
        scale: 0.4;
        left: -93%;
        top: -27%;
    }

    .AR-logo::after,
    .AR-logo::before {
        width: 200px;
    }

    .AR-logo img {
        width: 79px;
        height: 39.5px;
    }

    .AR-logo {
        margin-top: 5%;
        padding-bottom: 10%;
    }
}

@media (max-width:430px) {
    .ar-left video {
        width: 118.6%;
        bottom: 2.8%;
        left: -2.5%;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }

    .AR-bg-black {
        bottom: 1%;
    }

    .arpattern {
        scale: 0.8;
        left: -26%;
        top: -6%;
    }
}

@media (max-width: 414px) {
    .ar-left video {
        width: 120%;
        bottom: 2.3%;
        left: -2.5%;
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
    }

    .arpattern {
        scale: 0.8;
        left: -27%;
        top: -4%;
    }
}

@media (max-width:412px) {
    .ar-left video {
        width: 119%;
        bottom: 2.8%;
        left: -3%;
        border-top-left-radius: 38px;
        border-bottom-left-radius: 38px;
    }
}

@media (max-width: 393px) {
    .ar-left video {
        bottom: 2.5%;
        left: -3.5%;
        width: 119.6%;
    }

    .AR-bg-black {
        bottom: 1%;
    }

    .arpattern {
        scale: 0.7;
        left: -40%;
        top: -3%;
    }

}

@media (max-width:390px) {
    .ar-left video {
        width: 119.8%;
        bottom: 2.5%;
        left: -3.4%;
        border-top-left-radius: 33px;
        border-bottom-left-radius: 33px;
    }

    .arpattern {
        scale: 0.7;
        left: -40%;
        top: -1%;
    }

    .AR-logo::after,
    .AR-logo::before {
        width: 150px;
    }
}

@media (max-width: 375px) {
    .ar-left video {
        width: 120%;
        bottom: 2.5%;
        left: -3.9%;
        border-top-left-radius: 33px;
        border-bottom-left-radius: 33px;
    }

    .arpattern {
        scale: 0.7;
        left: -42%;
        top: 2%;
    }
}

@media (max-width:360px) {
    .ar-left video {
        width: 121%;
        bottom: 2.6%;
        left: -4.2%;
        border-top-left-radius: 33px;
        border-bottom-left-radius: 33px;
    }

    .arpattern {
        scale: 0.7;
        left: -44%;
        top: 5%;
    }

}

@media (max-width:280px) {
    .AR-wrapper {
        padding-left: 22px;
    }

    .ar-right .ar-context p {
        width: 235px;
    }

    .ar-left video {
        width: 119.2%;
        bottom: 4.4%;
        left: -3.3%;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }

    .arpattern {
        scale: 0.5;
        left: -84%;
        top: -43%;
    }


}