@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 100;
}

.discoverymain {
    position: relative;
    height: 767px;
    z-index: 5;
}

.discovery-wrapper {
    position: relative;
    padding-top: 5%;
}

.discovery-bg {
    position: absolute;
    width: 100%;
    top: -5.5%;
    z-index: 2
}

.discovery-wrapper video {
    position: absolute;
    right: 0;
    width: 64.8%;
    top: 13.2%;
    opacity: 0.15;
}

.bg-image {
    width: 105%;
    position: absolute;
    z-index: 1;
    padding-left: 4%;
}

.ellipse1 {
    position: absolute;
    top: 97%;
    right: 0%;
    z-index: 2;
}

.discovery-inner-right {
    padding-top: 6%;
    position: relative;
    z-index: 1;
}

.main-heading,
.vector-dis {
    padding-left: 8%;
    position: relative;
    z-index: 9;
}

.vector-dis {
    position: relative;
}

.vector-dis img {
    position: absolute;
    top: -44px;
}

.main-heading h1 {
    font-family: 'Trap';
    font-size: 73.5px;
    font-weight: 600;
    line-height: 96.336%;
    /* 62.722px */
    letter-spacing: -2.572px;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: inherit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-heading h2 {
    color: #FFF;
    font-family: 'Trap';
    font-size: 48px;
    font-weight: 400;
    line-height: 93.336%;
    /* 44.801px */
    letter-spacing: -2.88px;
}

.embodiesbg {
    position: absolute;
    top: 88%;
    left: 11.9%;
    scale: 2;
    z-index: 9;
}

.embodies-inner {
    padding-left: 8%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 5%;
    row-gap: 44%;
    width: 70%;
    position: relative;
    z-index: 9;
}

.embodies-subsection h1,
.embodies-subsectionA h1 {
    color: #FFF;
    font-family: 'Trap';
    font-size: 24px;
    font-weight: 700;
    width: 67%;
}

.embodies-subsection p,
.embodies-subsectionA p {
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
}

.embodies-subsectionA {
    grid-column-start: 2;
}

.embodies-subsection,
.embodies-subsectionA {
    display: flex;
    gap: 13px;
}

.embodies-subsection h3,
.embodies-subsectionA h3 {
    color: #FFF;
    font-family: 'Trap';
    font-size: 13.791px;
    font-style: normal;
    font-weight: 400;
    line-height: 131.2%;
    /* 18.094px */
    letter-spacing: -1.172px;
    text-transform: uppercase;
}

.vignette-discovery {
    position: absolute;
    top: 13%;
    right: 0;
    width: 1823px;
    height: 699.03px;
    box-shadow: 600px 0px 90px 90px rgba(19, 19, 19, 1) inset;
    z-index: 1;
}

@media (max-width: 1680px) {
    .discovery-wrapper video {
        position: absolute;
        right: 0;
        width: 58.8%;
        top: 13.2%;
        opacity: 0.15;
    }

    .vignette-discovery {
        height: 571.03px;
        width: 1396px;
    }

    .embodiesbg {
        top: 70%;
        left: 13%;
    }

    .ellipse1 {
        top: 82.4%;
    }

    .embodies-inner {
        row-gap: 15%;
    }

    .bg-image {
        width: 100%;
        position: absolute;
        z-index: 1;
        padding-left: 4%;
    }
}

@media (max-width: 1584px) {
    .discovery-bg {
        top: -8.5%;
    }

    .bg-image {
        width: 117%;
    }

    .discovery-wrapper video {
        position: absolute;
        right: 0;
        width: 77.5%;
        top: 7.7%;
        opacity: 0.15;
    }

    .vignette-discovery {
        width: 1458px;
        height: 677.03px;
        top: 7.1%;
        box-shadow: 600px 0px 90px 90px rgba(19, 19, 19, 1) inset;
    }

    .ellipse1 {
        top: 95.4%;
    }

    .embodiesbg {
        top: 79%;
        left: 13.85%;
    }
}

@media (max-width: 1440px) {
    .ellipse1 {
        top: 97%;
    }
}

@media (max-width: 1440px) and (max-height: 900px) {
    .discovery-inner-right {
        padding-top: 3%;
    }

    .discovery-wrapper video {
        position: absolute;
        right: 0;
        width: 63.5%;
        top: 7.7%;
        opacity: 0.15;
    }

    .vignette-discovery {
        width: 1278px;
        height: 519.03px;
    }

    .embodiesbg {
        top: 61%;
        left: 14.5%;
    }

    .embodies-inner {
        row-gap: 30%;
    }

    .ellipse1 {
        top: 85.7%;
    }

    .bg-image {
        width: 100%;
    }

}

@media(max-width:1366px) {
    .ellipse1 {
        top: 104.7%;
    }

    .bg-image {
        height: 118.6%;
        padding-left: 5%;
    }

    .discovery-wrapper video {
        position: absolute;
        right: 0;
        width: 69.7%;
        top: 26%;
        opacity: 0.15;
    }

    .vector-dis img {
        position: absolute;
        top: -39px;
    }

    .discovery-bg {
        position: absolute;
        width: 100%;
        top: 11.5%;
    }

    .embodiesbg {
        position: absolute;
        top: 69%;
        left: 3.3%;
        scale: 1;
    }

    .vignette-discovery {
        position: absolute;
        top: 26%;
        right: 0;
        width: 1281px;
        height: 536px;
        box-shadow: 300px 0px 90px 90px rgba(19, 19, 19, 1) inset;
        z-index: 2;
        filter: blur(1px);
    }
}

@media (max-width: 1358px) {
    .vignette-discovery {
        width: 1276px;
    }

    .embodiesbg {
        position: absolute;
        top: 82%;
        left: 8.3%;
        scale: 1.3;
    }
}

@media(max-width:1280px) {
    .vignette-discovery {
        width: 1196px;
        height: 523px;
    }

    .ellipse1 {
        top: 93%;
    }

    .embodiesbg {
        position: absolute;
        top: 79%;
        left: 10.9%;
        scale: 1.5;
    }

    .bg-image {
        width: 100%;
    }

    .discovery-inner-right {
        padding-top: 9%;
    }
}

@media (max-width: 1024px) {
    .bg-image {
        width: 100%;
    }

    .vignette-discovery {
        width: 971px;
        height: 454px;
    }

    .embodies-inner {
        row-gap: 7%;
        width: 72%;
    }

    .ellipse1 {
        top: 83%;
    }
}

@media (max-width: 820px) {
    .discoverymain {
        padding-left: 32px;
        height: 556px;
    }

    .main-heading {
        padding-left: 0;
    }

    .main-heading h1 {
        font-size: 33.269px;
    }

    .main-heading h2 {
        font-size: 21.727px;
        padding-left: 4px;
    }

    .embodies-inner {
        display: flex;
        flex-direction: column;
        gap: 45px;
        padding-left: 0;
        padding-top: 12%;
    }

    .embodies-subsection h1,
    .embodies-subsectionA h1 {
        font-size: 16.922px;
    }

    .embodies-subsection p,
    .embodies-subsectionA p {
        font-size: 14.14px;
        margin: 0;
        width: max-content;
    }

    .vector-dis img {
        position: absolute;
        top: -29px;
        width: 214px;
        left: 0
    }

    .bg-image {
        top: 24%;
        width: 820px;
        height: 627px;
        padding-left: 0;
        left: 0;
    }

    .discoverymain::before {
        content: "";
        position: absolute;
        width: 820px;
        height: 406.309px;
        left: 0;
        top: 20%;
        background-image: url(http://localhost:3000/static/media/discoverybgmobile.e8252d5….svg);
        background-size: cover;
    }

    .embodiesbg {
        left: -10%;
        top: 78%;
    }

    .ellipse1 {
        display: none;
    }

    .discovery-wrapper video {
        width: 74.7%;
        top: 46%;
        opacity: 0.5;
    }

    .vignette-discovery {
        position: absolute;
        top: 45.6%;
        right: 0;
        width: 590px;
        height: 334.03px;
        box-shadow: 206px 93px 75px 92px rgba(19, 19, 19, 0.9) inset;
        z-index: 1;
    }

    .embodiesbg {
        left: -10%;
        top: 73%;
    }
}

@media (max-width:490px) {
    .discoverymain {
        padding-left: 32px;
        height: 556px;
    }

    .main-heading {
        padding-left: 0;
    }

    .main-heading h1 {
        font-size: 33.269px;
    }

    .main-heading h2 {
        font-size: 21.727px;
        padding-left: 4px;
        letter-spacing: -1.88px;
    }

    .embodies-inner {
        display: flex;
        flex-direction: column;
        gap: 45px;
        padding-left: 0;
        padding-top: 12%;
    }

    .embodies-subsection h1,
    .embodies-subsectionA h1 {
        font-size: 16.922px;
    }

    .embodies-subsection p,
    .embodies-subsectionA p {
        font-size: 14.14px;
        margin: 0;
        width: max-content;
    }

    .vector-dis img {
        position: absolute;
        top: -29px;
        width: 214px;
        left: 0
    }

    .bg-image {
        display: none;
    }

    .discoverymain::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 474.309px;
        left: 0;
        top: 20%;
        background-image: url(../../images/discoverybgmobile.svg);
        background-size: cover;
    }

    .embodiesbg {
        left: -10%;
        top: 78%;
    }

    .ellipse1 {
        display: none;
    }

    .discovery-wrapper video {
        width: 126.7%;
        top: 43%;
        opacity: 0.5;
    }

    .vignette-discovery {
        position: absolute;
        top: 42%;
        right: 0;
        width: 488px;
        height: 331.03px;
        box-shadow: 206px 93px 75px 92px rgba(19, 19, 19, 0.9) inset;
        z-index: 1;
    }

    .embodiesbg {
        left: -10%;
        top: 73%;
    }
}

@media (max-width:450px) {
    .discoverymain {
        padding-left: 32px;
        height: 556px;
    }

    .main-heading {
        padding-left: 0;
    }

    .main-heading h1 {
        font-size: 33.269px;
    }

    .main-heading h2 {
        font-size: 21.727px;
        padding-left: 4px;
    }

    .embodies-inner {
        display: flex;
        flex-direction: column;
        gap: 45px;
        padding-left: 0;
        padding-top: 12%;
    }

    .embodies-subsection h1,
    .embodies-subsectionA h1 {
        font-size: 16.922px;
    }

    .embodies-subsection p,
    .embodies-subsectionA p {
        font-size: 14.14px;
        margin: 0;
        width: max-content;
    }

    .vector-dis img {
        position: absolute;
        top: -29px;
        width: 214px;
        left: 0
    }

    .bg-image {
        display: none;
    }

    .discoverymain::before {
        content: "";
        position: absolute;
        width: 429px;
        height: 406.309px;
        left: 0;
        top: 20%;
        background-image: url(../../images/discoverybgmobile.svg);
        background-size: cover;
    }

    .embodiesbg {
        left: -10%;
        top: 78%;
    }

    .ellipse1 {
        display: none;
    }

    .discovery-wrapper video {
        width: 126.7%;
        top: 43%;
        opacity: 0.5;
    }

    .vignette-discovery {
        position: absolute;
        top: 43%;
        right: 0;
        width: 434px;
        height: 285.03px;
        box-shadow: 206px 93px 75px 92px rgba(19, 19, 19, 0.9) inset;
        z-index: 1;
    }

    .embodiesbg {
        left: -10%;
        top: 73%;
    }
}

@media (max-width:420px) {
    .discoverymain::before {
        width: 420px;
    }
}

@media (max-width:414px) {
    .discoverymain::before {
        width: 412px;
    }

    .vignette-discovery {
        position: absolute;
        top: 43%;
        right: 0;
        width: 434px;
        height: 273.03px;
        box-shadow: 206px 93px 75px 92px rgba(19, 19, 19, 0.9) inset;
        z-index: 1;
    }
}

@media (max-width:412px) {
    .discovery-wrapper video {
        width: 132.7%;
        top: 43%;
        opacity: 0.5;
    }

    .discoverymain::before {
        width: 410px;
    }
}

@media (max-width: 393px) {
    .discovery-wrapper video {
        width: 132.7%;
        top: 45%;
        opacity: 0.5;
    }

    .discoverymain::before {
        width: 393px;
    }

    .vignette-discovery {
        height: 279.03px;
    }
}

@media (max-width:390px) {
    .discoverymain::before {
        width: 390px;
    }

    .vignette-discovery {
        height: 271.03px;
        top: 47.3%;
    }

    .discovery-wrapper video {
        top: 48%;
    }
}

@media (max-width:375px) {
    .discoverymain::before {
        width: 375px;
    }

    .discovery-wrapper video {
        width: 126.7%;
        top: 49%;
        opacity: 0.5;
    }

    .vignette-discovery {
        top: 46.3%;
    }
}

@media (max-width:360px) {
    .discoverymain::before {
        width: 360px;
        top: 23%;
    }

    .discovery-wrapper video {
        width: 121.7%;
        top: 57%;
        opacity: 0.5;
    }

    .vignette-discovery {
        position: absolute;
        top: 43%;
        right: 0;
        width: 434px;
        height: 290.03px;
        box-shadow: 206px 93px 75px 92px rgba(19, 19, 19, 0.9) inset;
        z-index: 1;
    }

    .embodiesbg {
        left: -19%;
        top: 75%;
        scale: 0.8;
    }

    .vector-dis img {
        position: absolute;
        top: -26px;
        width: 214px;
        left: 0;
    }
}

@media (max-width:280px) {
    .discoverymain::before {
        width: 100%;
        top: 18%;
    }

    .discoverymain {
        padding-left: 22px;
    }

    .discovery-wrapper video {
        width: 104.7%;
        top: 70%;
        opacity: 0.7;
    }

    .vignette-discovery {
        position: absolute;
        top: 69%;
        right: 0;
        width: 434px;
        height: 158.029px;
    }

    .embodiesbg {
        left: -24%;
        top: 73%;
    }

    .embodies-subsection p,
    .embodies-subsectionA p {
        font-size: 13.14px;
    }
}