@font-face {
    font-family: 'Danken';
    src: url('../../Fonts/Danken-RegularStripesRounded.ttf');
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 100;
}

.API-wrapper {
    position: relative;
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    gap: 8%;
}

.API-wrapper-bg {
    position: absolute;
    width: 100%;
    top: -46%;
    z-index: 11;
}

.API-wrapper video {
    position: absolute;
    width: 83%;
    right: 0;
    top: -29%;
    opacity: 1;
}

.API-embodies {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 10%;
    padding-left: 8%;
    position: relative;
    z-index: 1;
}

.API-heading {
    padding-left: 8%;
    position: relative;
    z-index: 1;
}

.API-heading h1 {
    font-family: 'Trap';
    font-size: 73.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 95.336%;
    /* 62.722px */
    letter-spacing: -2.572px;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.API-heading img {
    margin-top: -4%;
    padding-left: 1.1%;
    scale: 1.1;
}

.API-subsection h2 {
    color: #FFF;
    font-family: 'Danken';
    font-size: 56.727px;
    font-style: normal;
    font-weight: 400;
}

.API-subsection h1 {
    color: #FFF;
    font-family: 'Trap';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 131.2%;
    /* 31.488px */
    letter-spacing: -0.12px;
    text-transform: uppercase;
    padding-left: 6%;
}

.API-subsection p {
    padding-left: 6%;
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 131.2%;
    /* 20.992px */
    letter-spacing: -0.4px;
}

.gradient-II {
    position: absolute;
    background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
    width: 241px;
    height: 858px;
    border-radius: 858px;
    opacity: 0.4;
    filter: blur(160px);
    top: -63%;
    right: 17%;
    z-index: 999;
    transform: rotate(-90deg);
}

.vignette-API img {
    position: absolute;
    right: 0;
    top: -30%;
    width: 1768px;
    height: 893px;
    z-index: 0;
}


@media (max-width: 1680px){
    .vignette-API img {
        width: 1410.31px;
        height: 827.86px;
        right: -2px;
    }
    .gradient-II{
        right: 29%;
    }
}
@media (max-width:  1584px) {
    .API-wrapper {
        margin-top: 19.6%;
    }

    .API-wrapper-bg {
        top: -43%;
    }

    .vignette-API img {
        position: absolute;
        right: 0;
        top: -29%;
        width: 1063.44px;
        height: 598.17px;
        z-index: 0;
    }

    .API-wrapper video {

        width: 70%;
    }

    .gradient-II {
        position: absolute;
        background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
        width: 241px;
        height: 545px;
        border-radius: 858px;
        opacity: 0.4;
        filter: blur(160px);
        top: -42%;
        right: 17%;
        transform: rotate(-90deg);
        z-index: 999;
    }
}

@media (max-width: 1440px) and 
(max-height: 900px) {
    .vignette-API img {
        width: 1104.31px;
        height: 582.86px;
    }
    .API-wrapper video {
        right: 1px;
    }
}

@media (max-width:1366px) {
    .API-wrapper-bg {
        position: absolute;
        width: 100%;
        top: -38%;
        z-index: 2;
    }

    .API-wrapper {
        position: relative;
        margin-top: 22.8%;
        display: flex;
        flex-direction: column;
        gap: 8%;
    }

    .API-wrapper video {
        width: 90%;
        right: 2px;
    }

    .gradient-II {
        position: absolute;
        background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
        width: 241px;
        height: 545px;
        border-radius: 858px;
        opacity: 0.4;
        filter: blur(160px);
        top: -42%;
        right: 23%;
        transform: rotate(-90deg);
        z-index: 999;
    }

    .vignette-API img {
        width: 1232px;
        height: 696px;
    }
}
@media (max-width:1024px){
    .vignette-API img {
        position: absolute;
        right: 0px;
        top: -29%;
        width: 925px;
        height: 519px;
        z-index: 0;
    }
    .API-wrapper video{
        right: 2px;
    }
}
@media (max-width:820px) {
    
    .API-wrapper {
        overflow: hidden;
    }

    .API-heading h1 {
        font-size: 33.27px;
    }

    .API-heading img {
        width: 216.558px;
        margin-top: -14%;
        padding-left: 3.1%;
    }

    .API-embodies {
        display: flex;
        flex-direction: column;
        margin-top: 55%;
        gap: 50px;
    }

    .API-subsection h2 {
        font-size: 38.546px;
    }

    .API-subsection h1 {
        font-size: 16.92px;
    }

    .API-subsection p {
        font-size: 14.14px;
        margin-top: 0;
    }

    .API-wrapper video {
        top: 1%;
        width: 135%;
    }

    .vignette-API img {
        position: absolute;
        right: 0;
        top: 0%;
        width: 820px;
        height: 634px;
        z-index: 0;
        left: 0;
    }

    .gradient-II {
        position: absolute;
        background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
        width: 75px;
        height: 184px;
        border-radius: 708px;
        opacity: 1;
        filter: blur(80px);
        top: 12%;
        right: 22%;
        z-index: 999;
        transform: rotate(-45deg);
        ;
    }
}
@media (max-width:490px) {
    .API-wrapper {
        overflow: hidden;
    }

    .API-heading h1 {
        font-size: 33.27px;
    }

    .API-heading img {
        width: 216.558px;
        margin-top: -14%;
        padding-left: 3.1%;
    }

    .API-embodies {
        display: flex;
        flex-direction: column;
        margin-top: 55%;
        gap: 50px;
    }

    .API-subsection h2 {
        font-size: 38.546px;
    }

    .API-subsection h1 {
        font-size: 16.92px;
    }

    .API-subsection p {
        font-size: 14.14px;
        margin-top: 0;
    }

    .API-wrapper video {
        top: 0%;
        width: 150%;
    }

    .vignette-API img {
        width: 100%;
        height: 411px;
    }

    .gradient-II {
        position: absolute;
        background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
        width: 75px;
        height: 184px;
        border-radius: 708px;
        opacity: 1;
        filter: blur(80px);
        top: 12%;
        right: 22%;
        z-index: 999;
        transform: rotate(-45deg);
        ;
    }
}
@media (max-width:450px) {
    .API-wrapper {
        overflow: hidden;
    }

    .API-heading h1 {
        font-size: 33.27px;
        line-height: 106.336%;
    }

    .API-heading img {
        width: 216.558px;
        margin-top: -14%;
        padding-left: 3.1%;
    }

    .API-embodies {
        display: flex;
        flex-direction: column;
        margin-top: 55%;
        gap: 50px;
    }

    .API-subsection h2 {
        font-size: 38.546px;
    }

    .API-subsection h1 {
        font-size: 16.92px;
    }

    .API-subsection p {
        font-size: 14.14px;
        margin-top: 0;
    }

    .API-wrapper video {
        top: 0%;
        width: 150%;
    }

    .vignette-API img {
        position: absolute;
        right: 0;
        top: 0%;
        width: 400px;
        height: 345px;
        z-index: 0;
        left: 0;
    }

    .gradient-II {
        position: absolute;
        background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
        width: 75px;
        height: 184px;
        border-radius: 708px;
        opacity: 1;
        filter: blur(80px);
        top: 12%;
        right: 22%;
        z-index: 999;
        transform: rotate(-45deg);
        ;
    }
}

@media (max-width: 430px) {
    .vignette-API img {
        position: absolute;
        right: 0;
        top: 0%;
        width: 432px;
        height: 373px;
        z-index: 0;
        left: 0;
    }

    .API-wrapper video {
        top: 1%;
        width: 150%;
    }
}

@media (max-width:360px) {
    .vignette-API img {
        width: 363px;
        height: 354px;
    }

    .API-wrapper video {
        top: 1%;
        width: 150%;
    }
}

@media (max-width:280px) {
    .vignette-API img {
        width: 280px;
        height: 255px;
    }
}