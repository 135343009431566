@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 100;
}

.sectionII {
    display: flex !important;
    justify-content: center;
    gap: 2%;
}

.sub-section {
    width: 22%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    color: #ffff;
}

.sub-section h1 {
    font-family: 'Trap';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    padding-top: 16px;
}

.sub-section p {
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    width: 70%;
    padding-top: 4rem;
}

.subsection-image {
    height: 26px;
}

@media (max-width:1680px) {
    .sectionII-bg {
        top: -23%;
    }

    .sub-section {
        width: 18%;
    }

    .sectionII {
        gap: 4%;
    }

    .homepage-sectionII .dots2 {
        left: 39.8%;
    }

    .homepage-sectionII .line,
    .homepage-sectionII .lineb {
        left: 61%;

    }

    .sub-section p {
        width: 90%;
    }

    .homepage-sectionII .dotted2 {
        left: 39%;
    }

    .homepage-sectionII .dotted {
        left: 16%;
    }

    .homepage-sectionII .line-2 {
        left: 15.05%;
    }

    .homepage-sectionII .dots {
        left: 15%;
    }
}

@media (max-width:1366px) {
    .sectionII {
        gap: 0;
    }

    .sectionII-bg {
        top: -31%;
    }

    .homepage-sectionII {
        margin-top: 18%;
    }

    .sub-section {
        width: 24%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        color: #ffff;
    }

    .homepage-sectionII .line,
    .homepage-sectionII .lineb {
        left: 60%;

    }

    .homepage-sectionII .line {
        bottom: 84%;

    }

    .homepage-sectionII .dotted {
        left: 11.5%;
        bottom: -35%;
    }

    .homepage-sectionII .dotted2 {
        bottom: -51%;
        left: 36%;
    }

    .homepage-sectionII .line-2 {
        left: 10.05%;
        bottom: -8%;
    }

    .homepage-sectionII .dots {
        left: 10%;
        bottom: -23%;
    }

    .homepage-sectionII .dots2 {
        left: 36.8%;
    }

    .sub-section p {
        width: 76%;
        padding-top: 0;
    }

    .sub-section h1 {
        width: 80%;
    }
}
@media (max-width: 1024px) {
    .sub-section{
        width: 25%;
    }
}
@media (max-width:820px){
    .sectionII {
        flex-direction: column;
        padding-left: 24%;
        position: relative;
        z-index: 3;
        gap: 120px;
    }

    .sub-section {
        width: 76%;
    }

    .sub-section h1 {
        font-size: 21.216px;
        padding-left: 13px;
        width: 84%;

    }

    .sub-section p {
        font-size: 14.144px;
        padding-left: 13px;
        width: 80%;
    }

    .homepage-sectionII .dots {
        top: 20.5%;
        left: 18%;
    }

    .homepage-sectionII .dots2 {
        left: 22%;
        top: 38%;
    }

    .homepage-sectionII .line {
        bottom: 22%;
        left: 18%;
    }

    .homepage-sectionII .lineb {
        left: 18%;
    }

    .homepage-sectionII .line-2 {
        left: 18.3%;
        top: 12%;
    }

    .homepage-sectionII .dotted {
        display: none;
    }

    .homepage-sectionII .dotted2 {
        left: 18%;
        bottom: 30%;
        scale: 0.7;
    } 
}
@media (max-width:490px) {
    .sectionII {
        flex-direction: column;
        padding-left: 100px;
        position: relative;
        z-index: 3;
        gap: 120px;
    }

    .sub-section {
        width: 100%;
    }

    .sub-section h1 {
        font-size: 21.216px;
        padding-left: 13px;
        width: 84%;

    }

    .sub-section p {
        font-size: 14.144px;
        padding-left: 13px;
        width: 80%;
    }

    .homepage-sectionII .dots {
        top: 20.5%;
        left: 18.1%;
    }

    .homepage-sectionII .dots2 {
        left: 19%;
        top: 38%;
    }

    .homepage-sectionII .line {
        bottom: 22%;
        left: 18%;
    }

    .homepage-sectionII .lineb {
        left: 18%;
    }

    .homepage-sectionII .line-2 {
        left: 18.3%;
        top: 12%;
    }

    .homepage-sectionII .dotted {
        display: none;
    }

    .homepage-sectionII .dotted2 {
        left: 18%;
        bottom: 27%;
        scale: 0.7;
    }
}
@media (max-width:450px) {
    .sectionII {
        flex-direction: column;
        padding-left: 100px;
        position: relative;
        z-index: 3;
        gap: 120px;
    }

    .sub-section {
        width: 100%;
    }

    .sub-section h1 {
        font-size: 21.216px;
        padding-left: 13px;
        width: 84%;

    }

    .sub-section p {
        font-size: 14.144px;
        padding-left: 13px;
        width: 80%;
    }

    .homepage-sectionII .dots {
        top: 20.5%;
        left: 18%;
    }

    .homepage-sectionII .dots2 {
        left: 22%;
        top: 38%;
    }

    .homepage-sectionII .line {
        bottom: 22%;
        left: 18%;
    }

    .homepage-sectionII .lineb {
        left: 18%;
    }

    .homepage-sectionII .line-2 {
        left: 18.3%;
        top: 12%;
    }

    .homepage-sectionII .dotted {
        display: none;
    }

    .homepage-sectionII .dotted2 {
        left: 18%;
        bottom: 30%;
        scale: 0.7;
    }
}

@media (max-width:360px) {
    .homepage-sectionII .lineb {
        bottom: -5%;
    }
}

@media (max-width:280px) {
    .sectionII{
        padding-left: 80px;
    }
}