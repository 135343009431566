@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 100;
}

.medical-wrapper {
    position: relative;
    display: flex;
    padding-top: 2%;
    gap: 10%;

}

.medical-bg {
    position: absolute;
    width: 100%;
    top: -16%;
}

.medical-wrapper-inner-left,
.medical-wrapper-inner-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.medical-wrapper-inner-right {
    z-index: 6;
    flex: 1;
}

.medical-wrapper-inner-left {
    position: relative;
    padding-left: 8%;
    padding-top: 18.4%;
    z-index: -1;
    flex: 1;
}

.medical-wrapper-inner-left video {
    position: absolute;
    width: 117%;
    z-index: -1;
    opacity: 0.6;
    top: 2%;
    left: 0;
}

.square {
    position: absolute;
    top: 15%;
    left: 50%;
    animation: increase 2s infinite, movementLeft 8s infinite ease-in-out
}

@keyframes increase {
    0% {
        scale: 1;
    }

    50% {
        scale: 1.5;
    }

    100% {
        scale: 1;
    }

}

@keyframes movementLeft {
    0% {
        left: 50%;
    }

    100% {
        left: 80%;
    }

}

.medical-wrapper-inner-right h1 {
    width: 68%;
    font-family: 'Trap';
    font-size: 73.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 120.336%;
    /* 88.447px */
    letter-spacing: -2.572px;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.medical-paragraph {
    width: 472px;
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 159.836%;
    /* 25.574px */
    letter-spacing: -0.56px;
}

.bacteria-large {
    position: absolute;
    right: 55%;
    top: -5%;
    opacity: 0.6;
    animation: rotate 20s infinite ease-in-out;
}

.bacteria-mid {
    position: absolute;
    right: 52%;
    opacity: 0.6;
    top: 7%;
    animation: rotate 20s infinite ease-in-out;
}

.bacteria-small {
    position: absolute;
    right: 50%;
    opacity: 0.6;
    animation: rotate 20s infinite ease-in-out;
}

.context-bullets-med {
    padding-top: 5%;
}

@keyframes rotate {

    0%,
    100% {
        rotate: 0deg;
    }

    50% {
        rotate: 360deg;
    }
}

.vignette-medical img {
    position: absolute;
    top: 0%;
    left: -2%;
    width: 1191.31px;
    height: 638.86px;
    z-index: -1;
}



@media (max-width: 1680px){
    .vignette-medical img {
        width: 1046.31px;
        height: 573.86px;
    }
    .bacteria-large {
        position: absolute;
        right: 48%;
        top: -5%;
        opacity: 0.6;
        animation: rotate 20s infinite ease-in-out;
    }
    .bacteria-mid {
        position: absolute;
        right: 44%;
        opacity: 0.6;
        top: 7%;
        animation: rotate 20s infinite ease-in-out;
    }
    .bacteria-small {
        position: absolute;
        right: 41%;
        opacity: 0.6;
        animation: rotate 20s infinite ease-in-out;
    }
}

@media (max-width: 1584px) {
    .medical-bg {
        top: -19%;
    }

    .medical-wrapper-inner-left {
        padding-top: 23%;
    }

    .bacteria-large {
        position: absolute;
        right: 42%;
        top: -5%;
        opacity: 0.6;
        animation: rotate 10s infinite ease-in-out;
    }

    .bacteria-mid {
        position: absolute;
        right: 37%;
        opacity: 0.6;
        top: 7%;
        animation: rotate 10s infinite ease-in-out;
    }

    .bacteria-small {
        position: absolute;
        right: 35%;
        opacity: 0.6;
        animation: rotate 10s infinite ease-in-out;
    }

    .vignette-medical img {
        position: absolute;
        top: 1%;
        left: -2%;
        width: 948.31px;
        height: 504.86px;
        z-index: -1;
    }
}

@media (max-width: 1440px)and (max-height: 900px) {
    .vignette-medical img {
        width: 900.31px;
        height: 472.86px;
    }
}

@media (max-width: 1440px) {
    .bacteria-large {
        right: 40%;
    }

    .bacteria-mid {
        right: 35%;
    }

    .bacteria-small {
        right: 33%
    }
}

@media (max-width:1366px) {
    .medical-wrapper {
        position: relative;
        display: flex;
        margin-top: 2.8%;
        padding-top: 0%;
        gap: 10%;
    }

    .medical-bg {
        position: absolute;
        width: 100%;
        top: -18%;
    }

    .medical-wrapper-inner-left {
        position: relative;
        padding-left: 8%;
        padding-top: 26%;
    }

    .vignette-medical img {
        position: absolute;
        top: -1%;
        left: -2%;
        width: 846.31px;
        height: 495.86px;
        z-index: -1;
    }

    .bacteria-large {
        position: absolute;
        right: 37%;
        top: -5%;
        opacity: 0.8;
        animation: rotate 10s infinite ease-in-out;
    }

    .bacteria-mid {
        position: absolute;
        right: 32%;
        opacity: 0.8;
        top: 7%;
        animation: rotate 10s infinite ease-in-out;
    }

    .bacteria-small {
        position: absolute;
        right: 29%;
        opacity: 0.8;
        animation: rotate 10s infinite ease-in-out;
    }
    .medical-wrapper-inner-right h1{
        width: fit-content;
    }
}

@media (max-width:1280px) {
    .medical-wrapper-inner-right h1 {
        width: 85%;
    }

    .bacteria-small {
        right: 23%;
    }

    .bacteria-mid {
        right: 25%;
    }

    .bacteria-large {
        right: 31%;
    }

    .vignette-medical img {
        width: 789.31px;
        height: 438.86px;
    }
}

@media (max-width: 1024px) {
    .medical-wrapper-inner-left {
        padding-top: 34%;
    }

    .vignette-medical img {
        width: 569.31px;
        height: 324.86px;
    }

    .bacteria-small {
        right: 16%;
    }

    .bacteria-large {
        right: 25%;
    }

    .bacteria-mid {
        right: 19%;
    }
}

@media (max-width: 820px) {
    .medical-wrapper-inner-right {
        order: -1;
        width: 100%;
    }

    .medical-wrapper {
        flex-direction: column;
        padding-left: 32px;
        padding-top: 16.8%;
        overflow: hidden;
    }

    .medical-wrapper-inner-right h1 {
        font-size: 33.27px;
    }

    .medical-paragraph {
        width: 537px;
        font-size: 14px;
        padding-top: 50%;
    }

    .medical-wrapper-inner-left .bullets {
        width: 308px;
    }

    .medical-wrapper-inner-left {
        padding-top: 0;
        padding-left: 0;
    }

    .medical-wrapper-inner-left video {
        position: absolute;
        width: 207%;
        z-index: -1;
        opacity: 0.6;
        top: -340%;
        left: -32px;
    }

    .vignette-medical img {
        position: absolute;
        top: -343%;
        left: -5px;
        left: -43px;
        width: 874px;
        height: 479px;
        z-index: -1;
    }

    .square {
        position: absolute;
        top: -233%;
        animation: increase 2s infinite, movementLeft 8s infinite ease-in-out
    }

    .medical-wrapper-inner-right h1 {
        width: 30%;
    }

    @keyframes increase {
        0% {
            scale: 0.7;
        }

        50% {
            scale: 1;
        }

        100% {
            scale: 0.7;
        }

    }

    @keyframes movementLeft {
        0% {
            left: 50%;
        }

        100% {
            left: 85%;
        }

    }

    .bacteria-large {
        scale: 0.5;
        right: 78%;
    }

    .bacteria-mid {
        scale: 0.5;
        right: 77%;
        top: 0%;
    }

    .bacteria-small {
        scale: 0.5;
        right: 76%;
        top: -2%;
    }

    .gradient {
        left: 86%;
        top: -249%;
        width: 115px;
        height: 188px;
        opacity: 0.3;
        filter: blur(86px);
    } 
}
@media (max-width:490px) {
    .medical-wrapper-inner-right {
        order: -1;
        width: 100%;
    }

    .medical-wrapper {
        flex-direction: column;
        padding-left: 32px;
        padding-top: 16.8%;
        overflow: hidden;
    }

    .medical-wrapper-inner-right h1 {
        font-size: 33.27px;
        width: 60%;
    }

    .medical-paragraph {
        width: 438px;
        font-size: 14px;
        padding-top: 50%;
    }

    .medical-wrapper-inner-left .bullets {
        width: 308px;
    }

    .medical-wrapper-inner-left {
        padding-top: 0;
        padding-left: 0;
    }

    .medical-wrapper-inner-left video {
        position: absolute;
        width: 218%;
        z-index: -1;
        opacity: 0.6;
        top: -265%;
        left: -32px;
    }

    .vignette-medical img {
        position: absolute;
        top: -267%;
        left: -38px;
        width: 519px;
        height: 284px;
        z-index: -1;
    }

    .square {
        position: absolute;
        top: -233%;
        animation: increase 2s infinite, movementLeft 8s infinite ease-in-out
    }


    @keyframes increase {
        0% {
            scale: 0.7;
        }

        50% {
            scale: 1;
        }

        100% {
            scale: 0.7;
        }

    }

    @keyframes movementLeft {
        0% {
            left: 50%;
        }

        100% {
            left: 85%;
        }

    }

    .bacteria-large {
        scale: 0.5;
        right: 60%;
    }

    .bacteria-mid {
        scale: 0.5;
        right: 56%;
        top: 1%;
    }

    .bacteria-small {
        scale: 0.5;
        right: 56%;
        top: -2%;
    }

    .gradient {
        left: 86%;
        top: -249%;
        width: 115px;
        height: 188px;
        opacity: 0.3;
        filter: blur(86px);
    }
}
@media (max-width:450px) {
    .medical-wrapper-inner-right {
        order: -1;
        width: 100%;
    }

    .medical-wrapper {
        flex-direction: column;
        padding-left: 32px;
        padding-top: 16.8%;
        overflow: hidden;
    }

    .medical-wrapper-inner-right h1 {
        font-size: 33.27px;
    }

    .medical-paragraph {
        width: 312px;
        font-size: 14px;
        padding-top: 50%;
    }

    .medical-wrapper-inner-left .bullets {
        width: 308px;
    }

    .medical-wrapper-inner-left {
        padding-top: 0;
        padding-left: 0;
    }

    .medical-wrapper-inner-left video {
        position: absolute;
        width: 218%;
        z-index: -1;
        opacity: 0.6;
        top: -249%;
        left: -32px;
    }

    .vignette-medical img {
        position: absolute;
        top: -251%;
        left: -38px;
        width: 425px;
        height: 225px;
        z-index: -1;
    }

    .square {
        position: absolute;
        top: -233%;
        animation: increase 2s infinite, movementLeft 8s infinite ease-in-out
    }

    .medical-wrapper-inner-right h1 {
        width: 70%;
    }


    @keyframes increase {
        0% {
            scale: 0.7;
        }

        50% {
            scale: 1;
        }

        100% {
            scale: 0.7;
        }

    }

    @keyframes movementLeft {
        0% {
            left: 50%;
        }

        100% {
            left: 85%;
        }

    }

    .bacteria-large {
        scale: 0.5;
        right: 50%;
    }

    .bacteria-mid {
        scale: 0.5;
        right: 46%;
        top: 1%;
    }

    .bacteria-small {
        scale: 0.5;
        right: 47%;
        top: -2%;
    }

    .gradient {
        left: 86%;
        top: -249%;
        width: 115px;
        height: 188px;
        opacity: 0.3;
        filter: blur(86px);
    }
}

@media (max-width:430px) {
    .vignette-medical img {
        position: absolute;
        top: -251%;
        left: -38px;
        width: 464px;
        height: 253px;
        z-index: -1;
    }
}

@media (max-width:428px) {
    .bacteria-large {
        right: 54%;
    }

    .bacteria-mid {
        right: 50%;
    }

    .bacteria-small {
        right: 50%;
    }
}

@media (max-width: 412px) {
    .bacteria-large {
        right: 53%;
    }

    .medical-paragraph {
        width: 312px;
        font-size: 14px;
        padding-top: 55%;
    }

    .medical-wrapper-inner-left video {
        top: -258%;
    }

    .vignette-medical img {
        top: -262%;
    }
}

@media (max-width:393px) {
    .bacteria-large {
        right: 52%;
    }
}

@media (max-width: 375px) {
    .bacteria-large {
        right: 48%;
    }

    .bacteria-mid {
        right: 44%;
    }

    .bacteria-small {
        right: 44%;
    }
}

@media (max-width:360px) {
    .vignette-medical img {
        width: 385px;
        height: 244px;
    }

    .bacteria-large {
        scale: 0.5;
        right: 46%;
    }

    .bacteria-mid {
        scale: 0.5;
        right: 42%;
        top: 1%;
    }

    .bacteria-small {
        scale: 0.5;
        right: 43%;
        top: -2%;
    }
}

@media (max-width:280px) {
    .medical-wrapper {
        padding-left: 22px;
    }

    .medical-wrapper-inner-left video {
        position: absolute;
        width: 230%;
        z-index: -1;
        opacity: 0.6;
        top: -214%;
        ;
        left: -44px;
    }

    .vignette-medical img {
        width: 309px;
        height: 236px;
        top: -239%;
    }

    .bacteria-small {
        scale: 0.5;
        right: 26%;
        top: -1%;
    }

    .bacteria-mid {
        scale: 0.5;
        right: 28%;
        top: 2%;
    }

    .bacteria-large {
        scale: 0.5;
        right: 31%;
    }

    .medical-paragraph {
        width: 243px;
    }

    .medical-wrapper-inner-left .bullets {
        width: 264px;
    }
}