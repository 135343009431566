.registration-main {
    position: relative;
    z-index: 7;
}

.registration-main p {
    font-family: 'Trap';
}

.registration-main span {
    color: #EE1F46;
}

.heroSec-main {
    display: flex;
    justify-content: space-between;
    padding: 5% 5%;
    position: relative;
}

.heroSec-main::before {
    position: absolute;
    content: '';
    background-image: url(../../images/herobg.png);
    background-repeat: no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    z-index: -1;
}

.hero-sec-gradient {
    position: absolute;
    height: 120%;
    width: 100%;
    box-shadow: 600px 0px 90px 150px #0D1529 inset;
    left: 0;
    top: -20%;
    z-index: -1;
}

.main-right-date {
    background: #EE1F46;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    border-radius: 10px;
}

.main-right-date h2 {
    font-size: 20px;
    font-family: 'Trap';
    font-weight: 900;
    color: #fff;
}

.main-right-date-para {
    background: #ffff;
    padding: 2px 8px;
    border-radius: 10px;
}

.main-right-date-para p {
    margin: 0;
    color: #EE1F46;
    font-size: 15px;
    font-weight: 800;
    font-family: 'Trap';
}

.heroSec-main-left {
    padding: 5%;
}

.heroSec-main-left h1 {
    font-family: 'Trap';
    font-weight: 900;
    font-size: 61.334px;
    color: #ffff;
    width: 65%;
}

.heroSec-main-left p {
    font-family: 'Trap';
    font-size: 18px;
    color: #ffff;
}

.learn-sec-main {
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
}

.learn-sec-main-points {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.learn-sec-main-points-upper,
.learn-sec-main-points-lower {
    display: flex;
    align-items: center;
    gap: 2%;
    justify-content: center;
}

.registration-bottom-bar {
    display: flex;
    padding: 1% 5%;
    align-items: center;
    justify-content: space-between;
    background: rgb(152, 152, 152);
}

.registration-reserve {
    background: #EE1F46;
    color: white;
    padding: 12px;
    border-radius: 50px;
}

.registration-date,
.registration-address {
    display: flex;
    align-items: center;
    gap: 10px;
}

.registration-bottom-bar img {
    background: #EE1F46;
    padding: 7px;
    border-radius: 100%;
}

.learn-sec-main {
    background: #0D1529;
    padding: 3% 5%;
    gap: 20px;
    color: white;
}

.learn-sec-point {
    background: #20293D;
    padding: 2%;
    border-radius: 20px;
}

.learn-sec-main-heading h2 {
    font-size: 43px;
    font-family: 'Trap';
    font-weight: 800;
    color: #fff;
}

.learn-sec-point h3 {
    font-size: 18px;
    font-family: 'Trap';
}

.learn-sec-point p {
    font-size: 15px;
}

.bulletsection-main {
    display: flex;
    justify-content: space-between;
}

.bulletsection-left,
.bulletsection-right {
    width: 50%;
}

.bulletsection-left {
    padding-left: 5%;
    padding-top: 3%;
    background: rgb(152, 152, 152);
}

.bulletsection-right {
    padding-right: 5%;
    padding-top: 3%;
    background: rgb(152, 152, 152, 0.7);
    text-align: right;
}

.bulletsection-heading h2 {
    font-size: 43px;
    font-family: 'Trap';
    font-weight: 800;
}

.bulletsection-left p,
.bulletsection-right p {
    font-size: 18px;
    font-family: 'Trap';
}

.bulletsection-right h3 {
    font-size: 24px;
    font-family: 'Trap';
    font-weight: 600;
}


.contact-main {
    display: flex;
    flex-direction: column;
    gap: 40px;
    background: #0D1529;
    align-items: center;
    padding: 3% 1%;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: fit-content;
    align-items: center;
}

.input-divs {
    display: flex;
    gap: 25px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    color: #fff;
    background: #0D1529;
}

.registration-button {
    background: #0D1529;
    padding: 2%;
    color: #fff;
    border: 1px solid #fff;
}

.form-heading-brochure h1 {
    font-size: 43px;
    font-family: 'Trap';
    font-weight: 800;
    color: #fff;
}

.registration-reserve h3 {
    font-size: 15px;
    font-family: 'Trap';
    margin-bottom: 0;
}

.registration-date p {
    font-size: 12px;
}

.registration-address p {
    font-size: 12px;
}

@media (max-width:1681px) {
    .hero-sec-gradient {
        height: 121%;
    }
}

@media (max-width: 1601px) {}

@media (max-width: 1585px) {}

@media (max-width: 1537px) {}

@media (max-width: 1441px) {}

@media (max-width: 1401px) {
    .bulletsection-right {
        padding-right: 2%;
        padding-left: 3%;
    }
}

@media (max-width: 1381px) {
    .bulletsection-heading h2 {
        font-size: 40px;
        font-family: 'Trap';
        font-weight: 800;
    }
}

@media (max-width: 1367px) {}

@media (max-width: 1281px) {
    .registration-bottom-bar {
        padding: 1% 4%;
    }
}

@media (max-width: 1025px) {
    .heroSec-main-left {
        padding: 4%;
    }

    .hero-sec-gradient {
        position: absolute;
        height: 129%;
        width: 100%;
        box-shadow: 6px 0px 90px 150px #0D1529 inset;
        left: 0;
        top: -28%;
        z-index: -1;
    }

    .heroSec-main-left h1 {
        width: 75%;
    }
}


@media(max-width: 901px) {
    .heroSec-main-left h1 {
        font-family: 'Trap';
        font-weight: 900;
        font-size: 37.334px;
        color: #ffff;
        width: 100%;
    }

    .heroSec-main::before {
        background-size: cover;
        opacity: 0.8;
    }

    .heroSec-main-left,
    .heroSec-main-right {
        z-index: 1;
    }

    .heroSec-main-right {
        width: 50%;
        padding-left: 5%;
    }

    .learn-sec-main {
        padding: 5%;
    }

    .hero-sec-gradient {
        box-shadow: 600px 0px 90px 150px #0D1529 inset;
        opacity: 0.5;
    }

    .heroSec-main {
        flex-direction: column;
        /* background-image: url(../../images/herobg.png); */
        background-position: center;
        margin-top: -8%;
        padding-top: 10%;
        z-index: -1;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .heroSec-main-left p {
        font-family: 'Trap';
        font-size: 15px;
        color: #ffff;
    }

    .learn-sec-main-points-upper,
    .learn-sec-main-points-lower {
        flex-direction: column;
        gap: 20px;
        width: 100%;
        align-items: normal;
    }

    .learn-sec-point {
        padding: 6%;
    }

    .bulletsection-main {
        flex-direction: column;
    }

    .bulletsection-left,
    .bulletsection-right {
        width: 100%;
        padding-top: 5%;
    }

    .bulletsection-right {
        text-align: left;
        padding-left: 5%;
    }

    .registration-reserve {
        display: none;
    }

    .registration-bottom-bar {
        padding: 1% 5%;
        gap: 38px;
    }

    .contact-main {
        padding-top: 5%;
    }

    .bulletsection-heading h2 {
        font-size: 35px;
    }

    .bulletsection-right h3 {
        font-size: 18px;
    }

    .bulletsection-left p,
    .bulletsection-right p {
        font-size: 15px;
        font-family: 'Trap';
    }
}


@media (max-width: 601px) {
    .heroSec-main-left h1 {
        font-family: 'Trap';
        font-weight: 900;
        font-size: 37.334px;
        color: #ffff;
        width: 75%;
    }
}

@media (max-width: 541px) {
    .heroSec-main-right {
        width: 60%;
        padding-left: 5%;
    }
}

@media (max-width: 501px) {
    .learn-sec-main-heading h2 {
        font-size: 35px;
        font-family: 'Trap';
        font-weight: 800;
        color: #fff;
    }

    .form-heading-brochure h1 {
        font-size: 35px;
        font-family: 'Trap';
        font-weight: 800;
        color: #fff;
    }
}

@media (max-width: 481px) {
    .contact-main {
        padding: 5%;
    }
}

@media (max-width: 449px) {}

@media (max-width: 431px) {

    .registration-address p,
    .registration-date p {
        font-size: 8px;
    }

    .heroSec-main-left h1 {
        font-family: 'Trap';
        font-weight: 900;
        font-size: 37.334px;
        color: #ffff;
        width: 100%;
    }
}

@media (max-width: 415px) {
    .heroSec-main-right {
        width: 70%;
        padding-left: 5%;
    }
}

@media (max-width: 394px) {
    .heroSec-main-right {
        width: 75%;
        padding-left: 5%;
    }

    .learn-sec-main-heading h2 {
        font-size: 30px;
        font-family: 'Trap';
        font-weight: 800;
        color: #fff;
    }

    .bulletsection-heading h2 {
        font-size: 30px;
    }

    .form-heading-brochure h1 {
        font-size: 30px;
        font-family: 'Trap';
        font-weight: 800;
        color: #fff;
    }

    .bulletsection-left p,
    .bulletsection-right p {
        font-size: 12px;
        font-family: 'Trap';
    }

    .bulletsection-right h3 {
        font-size: 15px;
    }
}

@media (max-width: 391px) {}

@media (max-width: 386px) {}

@media (max-width: 381px) {}

@media (max-width: 376px) {}

@media (max-width: 361px) {
    .heroSec-main-right {
        width: 80%;
        padding-left: 5%;
    }
}

@media (max-width: 354px) {}

@media (max-width: 320px) {}

@media (max-width:280px) {}