.backdrop {
  background-color: rgb(18, 18, 18);
  top: 0%;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  margin-top: -6%;
  overflow: hidden;
}

.loader-bar {
  display: flex;
  flex-direction: column;
  position: relative;
}

.white {
  position: relative;
  z-index: 2;
}

.transparent {
  position: absolute;
  left: -1px;
  z-index: 5;
}

.loader-bar::before {
  content: '';
  position: absolute;
  height: 200px;
  width: 213px;
  z-index: 3;
  background-color: rgb(18, 18, 18);
  animation: move-up 15s ease-in-out 1s;
}

@keyframes move-up {
  0% {
    top: -1px;
  }

  20% {
    top: -160px;
  }

  100% {
    top: -220px
  }
}

@media (max-width: 1680px) {
  .backdrop {
    margin-top: -7%;
  }
}

@media (max-width: 1584px) {
  .backdrop {
    margin-top: -8%;
  }
}

@media (max-width:1366px) {
  .backdrop {
    margin-top: -9%;
  }
}

@media (max-width: 600px) {
  .backdrop {
    margin-top: -11%;
  }
}